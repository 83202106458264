
import { Component, Vue } from "vue-property-decorator";
// import { Field } from 'vant';
// import { accountLoginRequest } from "@/service/api";
import { request } from "@/service/libs/index";
import { Toast } from "vant";

@Component({
  name: "PerfectPayType",
  components: {
    // HelloWorld,
    // Field,
  },
})
export default class PerfectPayType extends Vue {
  private payList = {
    icon: require("@/assets/paysuccess-icon.png"),
    name: "Perfect Money",
    min: 1,
    max: 2,
    moneyMin: 10,
    moneyMax: "50,000",
  };

  private money: any = "";
  private perfectResult: any = {};
  private perfectOrderResult: any = {};
  private perfectActionUrl: string = "https://perfectmoney.com/api/step1.asp";
  // 下单
  private async handleBuy() {
    Toast.loading({
      message: this.$t("loading") as string,
      forbidClick: true,
      loadingType: "spinner",
      duration: 0,
    });
    let amount: number = Number(this.money);
    let result: any = await request({
      method: "GET",
      path: "/v1/api/pay/placeOrder/perfectMoney?amount=" + amount,
    });
    console.log(result);
    this.perfectOrderResult = result;
    if (this.perfectOrderResult && this.perfectOrderResult.PAYMENT_ID) {
      // 保存订单号在本地，已供后续成功和失败页面调用
      localStorage.setItem("perfect-orderId", result.PAYMENT_ID);
      // this.$refs.fromRef?.submit()
      let fromRef: any = document.getElementById("fromRef");
      console.log(111, fromRef);
      setTimeout(() => {
        fromRef.submit();
      }, 1000);
    }
  }
  // 查询单个支付渠道
  private async getPayInfoByChannelId() {
    let result: any = await request({
      method: "GET",
      path: "/v1/api/pay/channel/info?channelId=100001",
    });
    this.perfectResult = result;
  }

  //限制只能输入两位小数

  private limitInput(e: any) {
    e.target.value = e.target.value.match(/^\d*(\.?\d{0,1})/g)[0] || null;
  }

  mounted() {
    this.getPayInfoByChannelId();
  }
}
